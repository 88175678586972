import image from './image.jpg'

export default function Home(){
    function onClickRedirect(){
        window.location.href='margins'
    }

    return(
        <div className='align-center'>
            <br/><br/>
            {/* <h3>This app is currently compatible with desktop browsers, a mobile version is coming soon.</h3><br/> */}
            <img src={image} alt="" className="responsive"></img><br/><br/>
            <button onClick={onClickRedirect} style={{color:'white',background:'#1b4b57', boxShadow:'none'}}>ENTER</button>
        </div>
    )
}