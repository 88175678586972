

export default function Contacts(){
    
    return(
        <div className='center'>
            <br/><br/>
            <h1 style={{color: "white"}}>Phone Number - 01706 550 825</h1><br/>
            <h1><a href="mailto:office@prevailaccountancy.co.uk" style={{textDecoration: "none", color:"white"}}>Email - office@prevailaccountancy.co.uk</a></h1><br/>
            <h1><a href="https://prevailaccountancy.co.uk" style={{textDecoration: "none", color:"white"}}>Company Website - prevailaccountancy.co.uk</a></h1><br/>
        </div>
    )
}
