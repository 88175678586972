import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './Prevail_Logo_BusinessAdvisors_FINAL_WHITE.jpg'

export default function TopSection(){
    return (
        <Navbar className="colour-nav" variant="dark">
        <Container>
        <Navbar.Brand href="home"><img src={logo} alt="Logo" height={'102px'} width={'264px'}/></Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="margins">Project Profitability Calculator</Nav.Link>
            <Nav.Link href="contact">Contact Us</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      );
}
