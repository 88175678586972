import { API } from "aws-amplify";
import { createFeedback } from "../graphql/mutations";
import { useEffect, useState } from "react";
import axios from "axios";



export default function Margins() {
  let bracket = 1
  function onClickRedirectAnalysis(){
    let analysistype = [
      `GREEN OUTCOME - THE PROFIT COVERS MORE THAN JUST YOUR OVERHEADS. This is a positive result.
      Things to consider:
      1. How big is the margin of safety? There is a big difference between £100 and £10,000. If you can make an extra £10,000 or maybe £20,000, your position is more secure, depending on how big your overheads are.
      2. Can you risk a month of delay? If you consider the project risky and the length might be longer, try to play with what-if analysis - put in an extra month and see the outcomes.
      3. Will you need to negotiate? It may be worth considering putting the margin slighty up.
      4. High margin of safety gives you some space in negotiating or winning a tender.
      5. I hope you added the Director's salary to the overhead figures`,
      `ORANGE OUTCOME - THE PROJECT IS JUST BREAKING EVEN, AND THE PROFIT COVERS ONLY THE COMPANY OVERHEADS.
      Things to consider:
      1. The project is high risk, and there is no more space for negotiation.
      2. Could you increase the margin? Try to increast the margin by a few % and see what happens. Sometimes just a small change can make a big difference.
      3. Will you need to negotiate? It may be worth considering putting the margin slighty up.
      4. Would you consider taking two more profitable projects instead of one with a low margin?
      5. Did you review the costs of goods sold? Maybe choosing less expensive materials or hiring a new employee would be more efficient than appointing a sub-contractor.
      6. Review your overheads and make sure that the company capacity for the project is correct.`,
      `RED OUTCOME - THE PROJECT WILL NOT COVER THE OVERHEADS HENCE IS A LOSS-MAKING PROJECT
      Things to consider:
      1. The project is high-risk and will not cover the overheads.
      2. Can you increase the margin? Try to increase the margin by a few % and see what happens. Sometimes just a small change can make a big difference.
      3. Review the overheads - did you correctly allocate the company capacity? Try the what-if scenario. Would you consider lowering your fixed costs?
      4. Would you consider taking two or more profitable projects insteads of one with a low margin?
      5. Did you review the costs of goods sold? Maybe choosing less expensive materials or hiring a new employee would be more efficient than appointing a sub-contractor.
      6. Review your overheads once more and make sure that the company capacity for the project is correct.`
    ]
    alert(analysistype[bracket])
  }



  
  const [smiley, setSmiley] = useState(0);

  function setSmiley1(){
    setSmiley(1)
  }
  
  function setSmiley2(){
    setSmiley(2)
  }

  function setSmiley3(){
    setSmiley(3)
  }

  const [ip, setIP] = useState("");
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
      };

    const addFeedback = async () => {
        try {
          // eslint-disable-next-line
            const newFeedback = await API.graphql({
                query: createFeedback,
                variables: {
                    input: {
                    // "email": document.getElementById('email').value,
                    // "email": "Placeholder@placeholder.com" ,
                    "feedback": document.getElementById('feedback').value,
                    "userIP":  ip,
                    // "company": "Placeholder",
                    "smiley": smiley
                }
                } 
              

            }) 
            document.getElementById('feedbackBox').innerHTML= '<p>Thank you for submitting your feedback!</p>'
        } 
        catch (error) {
            alert(error.errors[0].message)
            console.log(error)
        }
    }

    useEffect(()=>{
        getData();
    })

  var DesiredMargin
  var CostOfSale
  var Duration
  var Overheads
  var Capacity
  var GrossProfit = 0
  var SalePrice = 0
  var GrossProfitInFullMonth = 0
  var OverheadsInMonth = 0
  var SafetyMarginCent = 0
  var SafetyMarginPound = 0
  var MinProfitForBreakeven = 0
  var MinSaleValueToBreakeven = 0
  var MinGrossProfitMargin = 0

  function desiredMarginClick(){
    alert(`Gross Profit Margin is the % of the gross profit of the Sale value (gross profit/sale).
Gross profit is calculated by distracting costs of goods sold from the turnover. The gross profit
margin can only be between 0 – 99 % because, in this instance, the sale is the only value of
100%. Remember that sales costs are only costs directly related to the project.`)
  }

  function costOfSaleClick(){
    alert(`Cost of sales are all the costs which are directly related to the project:
a. materials
b. labour
c. sub-contract costs
d. equipment hires.
e. prelims
f. accommodation for the project or travel (if the project requires long journeys)`)
  }

  function durationClick(){
    alert(`How many months will the project last? If the project lasts less than a month, add the value as a number ex. (1.5 = 1.5 months).`)
  }
  
  function overheadsClick(){
    alert(`What are the company’s monthly expenses not directly related to the project? Such
expenses include:
a. Admin salaries
b. Insurance
c. Rent, rates, telephone rates
d. Training
e. Heat. Light, power, printing, stationery
f. Repairs, renewals, office costs`)
  }

  function capacityClick(){
    alert(`What % of your labour uses this project? How many projects like that could you run at the
same time? If you look at the company’s expenses not directly related to the project, what %
would this project need to cover?
For example, if I have 2 equal projects, which take all of my labour workforce, each would be
50% because they are similar in size and one uses half of the business’ capacity.`)
  }

  const handleChange = () => {

    
    DesiredMargin = (document.getElementById("DesiredMargin").value / 100)
    CostOfSale = document.getElementById("CostOfSale").value
    Duration = document.getElementById("Duration").value
    Overheads = document.getElementById("Overheads").value
    Capacity = document.getElementById("CompanyCapacity").value

    SalePrice = CostOfSale/(1-DesiredMargin)
    document.getElementById("Sale Price").textContent = (SalePrice.toFixed(2))


    GrossProfit = SalePrice - CostOfSale
    document.getElementById("Gross Profit").textContent = (GrossProfit.toFixed(2))

    GrossProfitInFullMonth = GrossProfit / Duration
    document.getElementById("Gross Profit In Full Month").textContent = (GrossProfitInFullMonth.toFixed(2))

    OverheadsInMonth = GrossProfitInFullMonth / Overheads
    document.getElementById("Overheads Covered Per Month").textContent = ((OverheadsInMonth*100).toFixed(2))

    SafetyMarginCent = OverheadsInMonth*100-Capacity
    document.getElementById("Safety Margin Cent").textContent = (SafetyMarginCent.toFixed(2))

    SafetyMarginPound = (GrossProfitInFullMonth - Overheads * (Capacity/100)) * Duration
    document.getElementById("Safety Margin Pound").textContent = (SafetyMarginPound.toFixed(2))

    MinProfitForBreakeven = Overheads * (Capacity/100) * Duration
    document.getElementById("Min Profit For Breakeven").textContent = (MinProfitForBreakeven.toFixed(2))

    MinSaleValueToBreakeven = Number(CostOfSale) + MinProfitForBreakeven
    document.getElementById("Min Sale Value To Breakeven").textContent = (MinSaleValueToBreakeven.toFixed(2))

    MinGrossProfitMargin = MinProfitForBreakeven / MinSaleValueToBreakeven
    document.getElementById("Min Gross Profit Margin").textContent = ((MinGrossProfitMargin*100).toFixed(2))

  
  if (SafetyMarginPound.toFixed(2)>0){
    bracket = 0
    document.getElementById('PoundSafetyMargin').className='bordered-grid-item-green'
    document.getElementById('CentSafetyMargin').className='bordered-grid-item-green'
    document.getElementById('Is Project Profitable').className='green-text'
    document.getElementById('Is Project Profitable').textContent='The project is profitable, and extra profit is £'+(SafetyMarginPound.toFixed(2))+' above the break even point.'
// eslint-disable-next-line
  } else if(SafetyMarginPound.toFixed(2)==0.00){
    bracket = 1
    document.getElementById('PoundSafetyMargin').className='bordered-grid-item-amber'
    document.getElementById('CentSafetyMargin').className='bordered-grid-item-amber'
    document.getElementById('Is Project Profitable').className='amber-text'
    document.getElementById('Is Project Profitable').textContent='The project covers only all the overheads and there is no extra profit beyond break even point.'
  } else if(SafetyMarginPound.toFixed(2)<0){
    bracket = 2
    document.getElementById('PoundSafetyMargin').className='bordered-grid-item-red'
    document.getElementById('CentSafetyMargin').className='bordered-grid-item-red'
    document.getElementById('Is Project Profitable').className='red-text'
    document.getElementById('Is Project Profitable').textContent="The project won't cover your costs - go for it if you dare"
  }    
  };
 

  

  return (
    <div>
      {/* <a href='contact' style={{color:'black'}}><h3>How to use the calculator?</h3></a> */}
        
      
        <div className="grid">

        <div className="bordered-grid-item">
        <br/>
          <label for="DesiredMargin">Desired Gross Profit Margin  (%)</label><br/>
          <input title="Choose from 0-99.99" type="number" step="0.01" id="DesiredMargin" name="DesiredMargin" min="0" max="99" defaultValue="0" onChange={handleChange}></input>
          <br/><br/><br/><br/><button onClick={desiredMarginClick}>help</button>
          <br/><br/><p>1</p>
        </div>

        <div className="bordered-grid-item">
        <br/>
          <label for="CostOfSale">Cost Of Sales  (£)</label><br/>
          <input title="What are the direct costs to complete the project? (labour, materials, etc.)" type="number" id="CostOfSale" name="CostOfSale" min="0" step="0.01" defaultValue="0" onChange={handleChange}></input>
          <br/><br/><br/><br/><button onClick={costOfSaleClick}>help</button>
          <br/><br/><p>2</p>
        </div>

        <div className="bordered-grid-item">
        <br/>
          <label for="Duration">Duration (Months)  </label><br/>
          <input title="How many months will this project take?" type="number" id="Duration" name="Duration" min="0" step="0.1" defaultValue="0" onChange={handleChange}></input>
          <br/><br/><br/><br/><button onClick={durationClick}>help</button>
          <br/><br/><p>3</p>
        </div>

        <div className="bordered-grid-item">
        <br/>
          <label for="Overheads">Overheads per Month  (£)</label><br/>
          <input title="What are your monthy overhead costs? (admin fees etc.)" type="number" id="Overheads" name="Overheads" min="0" step="0.01" defaultValue="0" onChange={handleChange}></input>
          <br/><br/><br/><br/><button onClick={overheadsClick}>help</button>
          <br/><br/><p>4</p>
        </div>

        <div className="bordered-grid-item">
          <br/>
          <label for="CompanyCapacity">Company capacity for the project (%)  </label><br/>
          <input title="What percent of your company is working on this project?" type="number" id="CompanyCapacity" name="CompanyCapacity" min="0" max="100" defaultValue="0" onChange={handleChange}></input>
          <br/><br/><br/><button onClick={capacityClick}>help</button>
           <br/><br/><p>5</p>
        </div>

        <div className="bordered-grid-item"><p>Sale Price (£):</p><p id="Sale Price">{SalePrice}</p></div>
        <div className="bordered-grid-item"><p>Gross Profit (£):</p><p id="Gross Profit">{GrossProfit}</p></div>
        <div className="bordered-grid-item"><p>Gross Profit In Full Month (£):</p><p id="Gross Profit In Full Month">{GrossProfitInFullMonth}</p></div>
        <div className="bordered-grid-item"><p>% Of Overheads Covered Per Month:</p><p id="Overheads Covered Per Month">{OverheadsInMonth*100}</p></div>
        <div className="bordered-grid-item-feedback" id="feedbackDIV">
        <div id='feedbackBox'>
            <p style={{fontWeight:'bold'}}>Give us feedback!</p>
          
            <div className="radio-container" style={{fontSize:25}}>
              <div className="radio-option">
                <label for="radio1">😃</label>
                <input type="radio" id="radio1" name="options" onClick={setSmiley3}/>
              </div>
              <div className="radio-option">
                <label for="radio2">😐</label>
                <input type="radio" id="radio2" name="options" onClick={setSmiley2}/>
              </div>
              <div className="radio-option">
                <label for="radio3">😕</label>
                <input type="radio" id="radio3" name="options" onClick={setSmiley1}/>
              </div>
            </div>


            <div><textarea id='feedback' placeholder='Feedback' rows="3" cols="25"></textarea></div>
            <div><button onClick={addFeedback}>Submit</button></div>
          </div>
        </div>
        <div className="bordered-grid-item" id='CentSafetyMargin' ><p>Margin of Safety (%):</p><p id="Safety Margin Cent">{SafetyMarginCent}</p></div>
        <div className="bordered-grid-item" id='PoundSafetyMargin' ><p>Margin of Safety (£):</p><p id="Safety Margin Pound">{SafetyMarginPound}</p></div>
        <div className="bordered-grid-item"><p>Minimum Gross Profit to Breakeven with given Cost of Sales (£):</p><p id="Min Profit For Breakeven">{MinProfitForBreakeven}</p></div>
        <div className="bordered-grid-item"><p>Minimum Sale Value To Breakeven with given Cost of Sales (£):</p><p id="Min Sale Value To Breakeven">{MinSaleValueToBreakeven}</p></div>
        <div className="bordered-grid-item"><p>Minimum Gross Profit Margin needed to cover Overheads (%):</p><p id="Min Gross Profit Margin">{MinGrossProfitMargin}</p></div>
        
        
          
          
          
          
          
          
        </div>
        <div >
          <p id='Is Project Profitable' style={{fontSize: 40, fontWeight:'bold'}}>Please enter some values to get started.</p>
          <button onClick={onClickRedirectAnalysis} style={{color:'white',background:'#1b4b57', boxShadow:'none'}}>OUTCOME REVIEW</button>
        </div>
    </div>
  );
}