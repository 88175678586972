import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from './Prevail_Logo_BusinessAdvisors_FINAL_WHITE.jpg'

export default function TopSectionMobile(){
    return (
        <Navbar className="colour-nav" variant="dark">
        <Container>
        <Navbar.Brand href="home"><img src={logo} alt="Logo" className='responsive-mobile'/></Navbar.Brand>
        </Container>
      </Navbar>
      );
}
