import image from './image.jpg'

export default function HomeMobile(){
    function onClickRedirect(){
        window.location.href='margins'
    }


    return(
        <div className='align-center'>
            <br/><br/>
            {/* <h3>This app is currently compatible with desktop browsers, a mobile version is coming soon.</h3><br/> */}
            <img src={image} alt="" className="responsive-mobile"></img><br/><br/>
            <p><a href="tel:01706 550 825" style={{textDecoration: "none", color:"white", fontWeight:"bold" }}>01706 550 825</a></p>
            <p><a href="mailto:office@prevailaccountancy.co.uk" style={{textDecoration: "none", color:"white", fontWeight:"bold"}}>office@prevailaccountancy.co.uk</a></p>
            <button onClick={onClickRedirect} style={{color:'white',background:'#1b4b57', boxShadow:'none'}}>ENTER</button>
        </div>
    )
}